
  .list-component {
    position: fixed;
    top: 370px;
    right: -100%;
    width: 600px;
    height: 100%;
    padding: 20px;
    transition: right 0.3s ease;
    z-index: 900;
    background-color: none;
    opacity: none;
    
  }
  
  .list-component.show {
    left: 0;
  }
  
  .list-component-content {
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .list-component-content::-webkit-scrollbar {
    display: none;
  }
  .list-logo {
    width: 2.5vw;
    height: auto;
    margin-right: 10px;
  }

  .list-items {
    display: flex;
    flex-direction: column;
    margin-bottom: 400px;
  }
  
  .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0.92;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 10px 0;
    text-align: justify;
    list-style: none;
    position: relative;
  }

  .entity-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .entity-name {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .btn-go-detail {
    background-color: #fdbf94;
    border-radius: 20px;
    text-align: center;
    padding: 5px 10px;
    position: relative;
    right: 0 ;
    border: none;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3),
	  -2px -2px 4px rgba(255, 255, 255, 0.3); 
    font-size: 13px; 
    color: #555;
    cursor: pointer;
    margin-left: 10px;
  }

  @media (max-width: 1000px) and (orientation: portrait) {
    .list-component {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      top: 60px;
      right: -120%;
      margin-left: -50px;
      width: 105vw;
      height: 100%;
      transition: top 0.3s ease;
      background-color: rgb(32, 32, 35, 0.5);
    }

    .list-component.show {
      right: 5vw;
    }

    .list-component-content {
      height: 50%;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      height: 800px;
    }

    .list-logo {
      width: 8vw;
      height: auto;
      margin-right: 10px;
    }
  
    .list-items {
      display: flex;
      flex-direction: column;
      margin-bottom: 250px;
    }
    
    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 0.9;
      background-color: #f9f9f9;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 10px;
      margin: 10px 0;
      text-align: justify;
      list-style: none;
      position: relative;
    }
  
    .entity-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
    }
  
    .entity-name {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      font-size: small;
    }
    
  }