.gps-btn {
    height: 40px;
    width: 170px;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    background-color: #fdbf94;
    font-size: medium;
	font-weight: 550;
    padding: 10px;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4),
	-2px -2px 4px rgba(255, 255, 255, 0.4); 
    
}
.App {
  text-align: center;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

.glow {
	box-shadow: inset 0 0 10px #48abe0, 0 0 50px 5px #48abe0;
}
.break {
	flex-basis: 100%;
	height: 0;
}
.ant-modal-content {
	background-color: transparent !important;
	box-shadow: none !important;
}

.ant-modal-header {
	background-color: transparent !important;
}

.search {
	width: 500px;
}

.pac-target-input {
	display: flex;
	margin: auto;
	width: 300px;
	height: 30px;
	border-radius: 10px;
}

.animation {
	/* background: black; */
	/* border-radius: 50%; */
	/* margin: 10px; */
	/* height: 60px; */
	/* width: 60px; */
  /* margin: auto; */

  /* display: block;
  margin-left: auto;
  margin-right: auto; */

	/* box-shadow: 0 0 0 0 rgba(0, 0, 0, 1); */
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		/* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); */
	}

	70% {
		transform: scale(1);
		/* box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); */
	}

	100% {
		transform: scale(0.95);
		/* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
	}
}

