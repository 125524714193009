.input-change-btn {
    display: none;
}
.input-autocomplete {
    z-index: 999;
    position: fixed;
    top: 10px;
    left: 120px;
}

.entity-list-btn {
    z-index: 999;
	position: fixed;
	background-color: #fdbf94;
	width: fit-content;
	height: auto;
	text-align: left;
	top: 103px;
	left: 240px;
    border: none;
	border-radius: 20px;
    padding: 8px;
    font-size: medium;
    text-align: justify;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3),
	-2px -2px 4px rgba(255, 255, 255, 0.3); 
    font-size: small; 
    color: white;
    cursor: pointer;
}
.filter-domi-btn {
    z-index: 999;
	position: fixed;
	background-color: #fdbf94;
	width: fit-content;
	height: auto;
	text-align: left;
	top: 140px;
	left: 240px;
    border: none;
	border-radius: 20px;
    padding: 8px;
    font-size: medium;
    text-align: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3),
	-2px -2px 4px rgba(255, 255, 255, 0.3); 
    font-size: small; 
    color: white;
    cursor: pointer;
}

.entity-list-btn,
.filter-domi-btn {
  background-color: #fdbf94;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.entity-list-btn.selected,
.filter-domi-btn.selected {
  background-color: #ffa463;  
}


.fake-modal-1 {
	z-index: 999;
	position: fixed;
	background-color: white;
	width: 340px;
	height: 130px;
	text-align: left;
	top: 50px;
	left: 100px;
	border-radius: 20px;
    font-size: 12px; 
    color: #555;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3),
	-2px -2px 4px rgba(255, 255, 255, 0.3);
	img {
		padding-top: 20px;
		padding-left: 20px;
	}
}

.txt-fake-modal-1 {
    margin-top: 5px;
    padding-left: 40px;
}

.filter-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
}
  
.btn-expand {
    width: 15px;
    position: absolute;
    height: 50px;
    top: 50%;
    right: -15px; 
    transform: translateY(-50%);
    background-color: #fff;
    border: none;
    border-radius: 0 30px 30px 0;
    cursor: pointer;
}
  
.fake-modal-2 {
	z-index: 999;
	position: fixed;
	background-color: white;
    min-width: 330px;
    max-width: 450px;
	width: fit-content;
	height: fit-content;
    padding: 5px;
	top: 185px;
	left: 100px;
	border-radius: 20px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3),
	-2px -2px 4px rgba(255, 255, 255, 0.3);
	img {
        margin: 10px;
        background-color: transparent;
        border-radius: 40px;
        transition: box-shadow 0.3s;
        cursor: pointer;
    }
}

.to-detail-modal {
	background-color: white;
	border-radius: 10px;
	text-align: center;
	width: fit-content;
	height: auto;
}

.type-store-icon {
    border-radius: 50%;
    box-shadow: 0 0 0 5px #fff;
    margin-top: -70px;
    width: 80px;
    height: auto;
}

.modal-detail {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.icon-ubi-modal {
	width: 2vw;
	height: auto;
    margin-right: 10px;
}

.entity-address-modal {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: large;
  }

.to-detail-btn {
	height: auto;
    width: 120px;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    background-color: #fdbf94;
    font-size: small;
    padding: 10px;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4),
	-2px -2px 4px rgba(255, 255, 255, 0.4); 
    
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
 
.icon-container img {
    width: 60px;
    height: 60px;
    margin-bottom: 5px;
  }

.icon-container p {
    margin: 0;
    font-size: 12px; 
    color: #555; 
  }

.icon-container-ext.visible {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

.icon-container-ext.hidden {
    display: none;
}
 
.icon-container-ext img {
    width: 60px;
    height: 60px;
    margin-bottom: 5px;
}

.icon-container-ext p {
    margin: 0;
    font-size: 12px; 
    color: #555; 
}

@media (max-width: 1000px) and (orientation: portrait) {
    .entity-list-btn {
        position: fixed;
        top: 7vh;
        left: 64vw;
        width: 122px;
        height: 20px;
        span {
            display: none;
        }
    }
    .entity-list-btn::after {
        content: 'Todos los servicios';
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        font-size: small;
        color: #ffffff;
    }
    .filter-domi-btn {
        position: fixed;
        top: 7vh;
        left: 25vw;
        width: 135px;
        height: 20px;
        span {
            display: none;
        }
    }
    .filter-domi-btn::after {
        content: 'Servicios a domicilio';
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: small;
        color: #ffffff;
    }
    .hide-list-btn {
        display: none;
    }
    .input-autocomplete {
        display: none;
    }
    .input-change-btn {
        background-color: #fdbf94;
        display: flex;
        position: fixed;
        top: 3vh;
        left: 64vw;
        width: 120px;
        height: 20px;
        border: none;
        border-radius: 20px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3),
        -2px -2px 4px rgba(255, 255, 255, 0.3);
        span {
            display: none;
        }
    }
    .input-change-btn::after {
        content: 'Cambiar ubicación';
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        font-size: small;
        color: #ffffff;
    }


    .fake-modal-1 {
        z-index: 999;
        position: fixed;
        background-color: rgba(255, 255, 255, 0.9);
        width: 100vw;
        height: auto;
        text-align: left;
        top: 5px;
        left: 0;
        border-radius: 20px;
        img {
            width: auto;
            height: 8vh;
            padding-top: 10px;
            padding-left: 20px;
            padding-bottom: 10px;
        }
        p {
            padding-left: 40px;
        }
    }

    .txt-fake-modal-1 {
        display: flex;
        margin-left: -20px;
    }

    .fake-modal-2 {
        top: auto;
        position: fixed;
        display: flex;
        align-items: center;
        width: 100vw;
        height: 10vh;
        bottom: 1px;
        left: -15px;
        justify-content: flex-start;
        border-radius: 10px;
        background: white;
        padding: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        overflow-x: auto;
        white-space: nowrap;
    }
      
    .fake-modal-2 .flex-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        padding-left: 10px;
        padding-right: 20px;
    }
      
    .fake-modal-2 img {
        width: 12vw;
        height: auto;
        margin-bottom: 5px;
        transition: transform 0.2s ease-in-out;
    }
      
    .fake-modal-2 p {
        display: none;
    }
      
    .fake-modal-2 .selected img {
        transform: scale(1.2);
    }
      
    .fake-modal-2 .selected p {
        display: block;
        font-size: 12px;
        color: #555;
        position: absolute;
        bottom: 60px;
    }
      
    .icon-container {
        position: relative;
    }
      
    .to-detail-modal {
        background-color: white;
        border-radius: 10px;
        text-align: center;
        width: fit-content;
        height: auto;
    }
      
    .icon-ubi-modal {
        width: 5vw;
    }
      
    .icon-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
       
    .icon-container .selected img {
        transform: scale(1.2);
    }
      
    .icon-container .selected p {
        display: block;
        font-size: 12px;
        color: #555;
        position: absolute;
        bottom: 70px; 
    }
    .fake-modal-2 img.glow {
        box-shadow: none;
    }
    .btn-expand {
        display: none;
    } 

    .icon-container-ext {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .icon-container-ext.visible {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .icon-container-ext.hidden {
        display: flex;
    }

}
