.header-image {
  background-size: cover;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  width: 68.3vw;
  height: 35vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.4),
    -2px -2px 4px rgba(255, 255, 255, 0.4);
}

.header-image-default {
  background-image: url(/public/img-tests/Default-vet.png);
}

.header-vet {
  background-image: url(/public/img-storetype/Veterinaria.png);
}

.header-petwalk {
  background-image: url(/public/img-storetype/Paseo.png);
}

.header-petshop {
  background-image: url(/public/img-storetype/Accesorios.png);
}

.header-foodstore {
  background-image: url(/public/img-storetype/Alimento.png);
}

.header-funeral {
  background-image: url(/public/img-storetype/Funerarias.png);
}

.header-training {
  background-image: url(/public/img-storetype/Adiestramiento.png);
}

.header-hairsalon {
  background-image: url(/public/img-storetype/Peluqueria.png);
}

.header-pharmacy {
  background-image: url(/public/img-storetype/Farmacia.png);
}

.header-hotel {
  background-image: url(/public/img-storetype/Hotel.png);
}

.header-transportation {
  background-image: url(/public/img-storetype/Transporte.png);
}

.name-logo {
  width: 200px;
  margin-top: 30px;
  margin-bottom: 70px;
}

.icon-name-logo {
  margin-top: 30px;
  margin-right: 30px;
  margin-left: -80px;
  width: auto;
  height: 10vh;
}

.vet-map {
  align-items: start;
  margin-bottom: 15px;
  min-width: 68vw;
  min-height: 10vw;
  max-width: auto;
  max-height: auto;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.4),
    -2px -2px 4px rgba(255, 255, 255, 0.4);
}

.map-size {
  height: 40vh;
}

.vet-map p {
  margin-top: -40px;
  margin-bottom: 30px;
  margin-left: 60px;
  font-weight: bold;
}

.animal-type {
  margin-right: 10px;
  margin-top: 20px;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.animal-type img {
  margin-bottom: 1vh;
  margin-right: 1vw;
  width: 40px;
  height: auto;
}

.animal-txt {
  margin-top: -8px;
  position: none;
  margin-left: none;
  font-size: x-small;
  font-weight: 400;
}

.animal-txt-1 {
  margin-top: -8px;
  position: none;
  margin-left: none;
  font-size: x-small;
  font-weight: 400;
}

.animal-type-txt {
  font-size: small;
  font-weight: 500;
  left: 35px;
  top: -20px;
  position: absolute;
}

.icon-map {
  padding: 10px;
  width: 3%;
  max-width: 15%;
}

.vet-description {
  font-weight: 100;
  position: relative;
  z-index: 2;
  padding: 3px;
  margin-top: -15px;
  margin-bottom: 15px;
  min-width: 68vw;
  min-height: 10vw;
  width: auto;
  height: fit-content;
  max-width: 68vw;
  max-height: auto;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.4),
    -2px -2px 4px rgba(255, 255, 255, 0.4);

  h2 {
    margin-left: -40px;
  }
}

.vet-description-txt-1 {
  margin-top: -1.5vw;
  margin-left: 40px;
  width: 20vw;
  font-size: smaller;
  white-space: wrap;
}

.vet-description-txt-2 {
  font-size: smaller;
  margin-top: -1vw;
  width: 10px;
  white-space: wrap;
}

.icon-description {
  padding: 20px;
  width: 10%;
  height: 1%;
  max-width: 15%;
  max-height: 5%;
  margin-right: -30px;
}

.service-detail {
  font-weight: 500;
  font-size: x-small;
  padding: 5px;
  width: fit-content;
  max-width: 200px;
  height: auto;
  text-align: center;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 0.1px;
  margin-top: 0.1px;
  white-space: nowrap;
}

.bg-service {
  background-color: #87bdff;
}

.bg-specialism {
  background-color: #89ff87;
}

.bg-surgery {
  background-color: #ccb0f5;
}

.flex-service-detail {
  margin-top: 1px;
  margin-left: -40px;
  max-width: calc(120% - 150px);
}

.vet-details {
  font-weight: 200;
  margin-right: 1vw;
  min-width: 29vw;
  min-height: 15vw;
  width: auto;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.4),
    -2px -2px 4px rgba(255, 255, 255, 0.4);
}

.vet-details ul {
  margin-top: 2vw;
  list-style-type: none;
  padding: 0;
}

.vet-details li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 1vw;
}

.vet-details a,
.vet-details span {
  color: #020203;
  margin-left: 1vw;
}

.vet-details a:hover {
  text-decoration: underline;
}

.icons-details {
  width: 2vw;
  margin-left: 1vw;
  margin-bottom: 0.1vw;
}

.flex-container-1 {
  margin-left: -3vw;
}

.flex-container-2 {
  margin-top: auto;
  margin-left: -9vw;
}

.flex-container-3 {
  margin-top: auto;
  margin-right: 1vw;
}

.detail-navigation {
  margin-top: 300px;
}

.ad1 {
  margin-left: 5px;
  width: 38vw;
  height: 15.5vw;
  background-color: #fefefe;
  border-radius: 20px;
  box-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.4),
    -2px -2px 4px rgba(255, 255, 255, 0.4);
}

.user-icons {
  margin-left: 15vw;
  margin-top: 2vw;
  width: 4vw;
}

.ad2 {
  margin-top: 160px;
  margin-left: auto;
  width: 12vw;
  height: 40vw;
  background-color: #fefefe;
  border-radius: 20px;
  box-shadow:
    2px 2px 4px rgba(0, 0, 0, 0.4),
    -2px -2px 4px rgba(255, 255, 255, 0.4);
}

.icons-desk {
  width: 5vw;
}

.btn-back {
  margin-left: 0px;
  margin-top: -120px;
  margin-bottom: 100px;
  font-size: 4vw;
}

@media (max-width: 1000px) and (orientation: portrait) {
  .header-image {
    background-size: cover;
    position: relative;
    background-position: top;
    background-repeat: no-repeat;
    z-index: 1;
    width: 68vw;
    height: 35vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow:
      2px 2px 4px rgba(0, 0, 0, 0.4),
      -2px -2px 4px rgba(255, 255, 255, 0.4);
  }

  .header-image-default {
    background-image: url(/public/img-tests/Default-vet.png);
  }

  .flex-container-2 {
    margin-top: 0vw;
    margin-left: -4vw;
  }

  .header-image {
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 40vw;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none;
  }

  .vet-description {
    display: flex;
    flex-direction: column;
    font-weight: 100;
    position: relative;
    z-index: 2;
    padding: 3px;
    margin-top: -15px;
    margin-bottom: 1px;
    margin-left: -1vw;
    min-width: 90vw;
    min-height: 10vw;
    width: 98vw;
    height: fit-content;
    max-width: 100vw;
    max-height: auto;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow:
      2px 2px 4px rgba(157, 140, 140, 0.4),
      -2px -2px 4px rgba(255, 255, 255, 0.4);

    h2 {
      margin-bottom: -10px;
    }
  }

  .vet-description-txt-1 {
    margin-top: -3vh;
    margin-left: 10vw;
    width: fit-content;
  }

  .vet-description-txt-2 {
    margin-top: -1vh;
    margin-left: 10vw;
    width: fit-content;
  }

  .flex-service-detail {
    margin-top: -2vw;
    margin-left: -10px;
    margin-right: -0.1vw;
  }

  .icon-description {
    padding: 2vw;
    width: 50px;
    height: 50px;
    max-width: 15%;
    max-height: 5%;
    margin-right: 0vw;
    margin-top: -3.5vh;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 40px;
    padding: 3px;
  }

  .animal-type {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .animal-txt {
    position: relative;
    margin-left: -60px;
    font-size: x-small;
  }

  .animal-txt-1 {
    position: relative;
    margin-left: -80px;
    font-size: x-small;
  }

  .animal-type img {
    margin-bottom: 1vh;
    margin-right: 1vw;
    width: 50px;
    height: 50px;
    margin-top: -5vh;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 40px;
    padding: 3px;
  }

  .animal-type-txt {
    font-size: small;
    font-weight: 500;
    left: -15px;
    top: -20px;
    display: flex;
    position: absolute;
  }

  .vet-details {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 3px;
    margin-top: 0.2vh;
    margin-bottom: 15vh;
    font-weight: 200;
    margin-right: 0vw;
    min-width: 29vw;
    min-height: 15vw;
    width: 98vw;
    height: fit-content;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow:
      2px 2px 4px rgba(0, 0, 0, 0.4),
      -2px -2px 4px rgba(255, 255, 255, 0.4);
  }

  .vet-details ul {
    margin-top: 2vw;
    list-style-type: none;
    padding: 0;
  }

  .vet-details li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 1vw;
  }

  .vet-details a,
  .vet-details span {
    color: #020203;
    margin-left: 1vw;
  }

  .vet-details a:hover {
    text-decoration: underline;
  }

  .icons-details {
    width: 4vw;
    margin-left: 1vw;
    margin-bottom: 0.1vw;
  }

  .vet-map {
    position: relative;
    align-items: start;
    margin-top: 0.2vw;
    margin-bottom: 16vh;
    min-width: 99vw;
    min-height: 40vw;
    max-width: auto;
    max-height: auto;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow:
      2px 2px 4px rgba(0, 0, 0, 0.4),
      -2px -2px 4px rgba(255, 255, 255, 0.4);
  }

  .map-size {
    height: 40vh;
  }

  .vet-map p {
    margin-top: -6vw;
    margin-bottom: 3vw;
    margin-left: 60px;
    font-weight: bold;
  }

  .icon-map {
    padding: 3vw;
    width: 3%;
    max-width: 15%;
  }

  .btn-back {
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    margin-left: 30px;
    margin-top: 3vh;
    margin-bottom: 0px;
    font-size: 8vw;
    z-index: 20;
  }

  .detail-navigation {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 1px;
    width: 100%;
    text-align: center;
    padding: 10px;
    z-index: 10;
    margin-left: -9vw;
    box-shadow: 0px -4px 4px rgba(169, 155, 155, 0.4);
  }

  .contact-txt-btn {
    margin-top: 5px;
  }

  .contact-container-btn {
    margin-top: 0px;
    padding: 0px;
    width: 7vw;
    height: auto;
    background-color: none;
    border-radius: 30px;
    margin-left: 10vw;
    box-shadow: none;
  }

  .map-txt-btn {
    text-align: 0;
    margin-top: 4px;
  }

  .map-container-btn {
    padding: 0px;
    width: 7vw;
    height: auto;
    background-color: none;
    border-radius: 30px;
    box-shadow: none;
  }

  .flex-container-1 {
    margin-left: 0;
  }

  .icons-desk {
    padding: 5px;
    margin-bottom: -2vw;
    width: auto;
    height: 5vh;
  }

  .ad2,
  .ad1,
  .name-logo,
  .icon-name-logo {
    display: none;
  }
}